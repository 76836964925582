import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SavePackageRequestInterface } from '@/features/packages/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { PackageTypeEnum } from '@/features/packages/redux/enums/packageTypeEnum'
import _ from 'lodash'

export default class SavePackageValidation
  implements
    ValidationInterface<SavePackageRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SavePackageRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required')),
      description: yup
        .string()
        .required(t('validation:required')),
      order: yup
        .number()
        .required(t('validation:required'))
        .min(
          0,
          t(
            'validation:field_must_be_greater_than',
            { min: 0 }
          )
        ),
      service_ids: yup
        .array()
        .of(yup.number().required())
        .required(t('validation:required')),
      type: yup
        .mixed<PackageTypeEnum>()
        .required(t('validation:required')),
      image: yup.mixed<File>().nullable(),
      price: yup
        .number()
        .required(t('validation:required'))
        .typeError(t('validation:required'))
        .min(
          0.01,
          t('validation:min', { min: 0.01 })
        ),
      points: yup
        .number()
        .nullable(t('validation:required'))
        .min(0, t('validation:min', { min: 0 })),
      discounted_price: yup
        .number()
        .nullable(t('validation:required'))
        .min(
          0.01,
          t('validation:min', { min: 0.01 })
        )
        .lessThan(
          yup.ref('price'),
          t('validation:less_than', {
            field: t(
              'form:labels.price'
            ).toLowerCase(),
          })
        )
        .transform(
          (value: number | null | string) =>
            _.isNaN(value) ? null : Number(value)
        ),
      discounted_points: yup
        .number()
        .nullable(t('validation:required'))
        .min(0, t('validation:min', { min: 0 }))
        .when('points', {
          is: (value: number | null | string) =>
            !_.isNaN(value) && !_.isNull(value),
          then: (schema) =>
            schema.lessThan(
              yup.ref('points'),
              t('validation:less_than', {
                field: t(
                  'form:labels.points'
                ).toLowerCase(),
              })
            ),
        })
        .transform(
          (value: number | null | string) =>
            _.isNaN(value) ? null : Number(value)
        ),
    })
  }

  defaultValues(): SavePackageRequestInterface {
    return {
      order: 0,
      name: '',
      price: 0,
      service_ids: [],
      type: PackageTypeEnum.POST,
      description: '',
      discounted_points: '',
      discounted_price: '',
      points: '',
    }
  }
}
