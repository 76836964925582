import React, { useEffect, useState } from 'react'
import {
  FormInterface,
  Select2OptionInterface,
} from '@/app/types'
import {
  PackageInterface,
  SavePackageRequestInterface,
} from '@/features/packages/redux/types'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SavePackageValidation from './validations/SavePackageValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Button } from '@/features/components/buttons/button'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import _ from 'lodash'
import { PackageTypeEnum } from '@/features/packages/redux/enums/packageTypeEnum'
import { Label } from '@/features/components/inputs/label'
import { MultiValue } from 'react-select'
import { useLazyGetServicesQuery } from '@/features/services/redux/serviceAPI'
import { AsyncPaginate } from 'react-select-async-paginate'
import { Query } from '@/utils/query'

const Form: React.FC<
  FormInterface<
    SavePackageRequestInterface,
    PackageInterface
  >
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
  ])
  const [getServices] = useLazyGetServicesQuery()
  const { schema, defaultValues } = useValidation(
    new SavePackageValidation(),
    t
  )
  const {
    control,
    handleSubmit,
    setValue,
    watch,
  } = useForm<SavePackageRequestInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const [selectedServices, setSelectedServices] =
    useState<MultiValue<Select2OptionInterface>>(
      []
    )

  const watchType = watch('type')

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('type', data.type)
      setValue('price', data.price)
      setValue('points', data.points)
      setValue('order', data.order)
      setValue(
        'discounted_price',
        data.discounted_price
      )
      setValue(
        'discounted_points',
        data.discounted_points
      )
      setValue('description', data.description)
      if (data.services) {
        setSelectedServices(
          data.services.map((service) => ({
            label: service.description,
            value: service.id.toString(),
          }))
        )
        setValue(
          'service_ids',
          data.services.map(
            (service) => service.id
          )
        )
      }
    }
  }, [data])

  const handleGetServices = async (
    value: string,
    prevOptions: unknown,
    additional:
      | { page: number | undefined }
      | undefined
  ) => {
    const page = 1

    try {
      if (!additional || !additional.page)
        throw new Error('No page provided')

      const { page } = additional

      let type: 'user' | 'post'

      switch (watchType) {
        case PackageTypeEnum.USER:
        case PackageTypeEnum.PREMIUM:
          type = 'user'
          break
        default:
          type = 'post'
      }

      const query = new Query()
        .page(page)
        .where('description', value)
        .where('type', type)

      const response = await getServices(
        query.url()
      ).unwrap()

      return {
        options: response.data.map((service) => ({
          label: service.description,
          value: service.id.toString(),
        })),
        hasMore:
          response.pagination.total_pages > page,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
        },
      }
    }
  }

  const handleChangeServices = (
    value: MultiValue<Select2OptionInterface>
  ) => {
    setSelectedServices(value)
    setValue(
      'service_ids',
      value.map((service) =>
        Number(service.value)
      )
    )
  }

  return (
    <form
      className={'flex flex-col gap-y-4'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className={'grid lg:grid-cols-4 gap-4'}
      >
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t('form:labels.name')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'name'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Select
                value={field.value}
                buttonClassNames={'!min-w-full'}
                label={t('form:labels.type')}
                onChange={(_, value) =>
                  field.onChange(value)
                }
              >
                {_.map(
                  PackageTypeEnum,
                  (value, key) => (
                    <Option
                      value={value}
                      key={key}
                    >
                      {t(
                        `utils:package_types.${value}`
                      )}
                    </Option>
                  )
                )}
              </Select>
            </FormControl>
          )}
          name={'type'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                label={t('form:labels.price')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'price'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                label={t('form:labels.points')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'points'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                label={t(
                  'form:labels.discounted_price'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'discounted_price'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                label={t(
                  'form:labels.discounted_points'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'discounted_points'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              className={'lg:col-span-2'}
              {...field}
              error={!!error}
            >
              <Input
                label={t(
                  'form:labels.description'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'description'}
          control={control}
        />

        <div>
          <Controller
            control={control}
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  min={0}
                  type={'number'}
                  label={t('form:labels.order')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={`order`}
          />
        </div>

        <Controller
          render={({ fieldState: { error } }) => (
            <FormControl
              className={
                'flex flex-col lg:col-span-3'
              }
              error={!!error}
            >
              <Label
                label={t('form:labels.services')}
              />
              <AsyncPaginate
                isMulti
                value={selectedServices}
                additional={{
                  page: 1,
                }}
                debounceTimeout={500}
                onChange={handleChangeServices}
                loadOptions={handleGetServices}
                classNames={{
                  control: () =>
                    '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent !min-h-[42px]',
                  menuList: () =>
                    '!max-h-[200px]',
                }}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'service_ids'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <div
              className={'flex flex-col gap-y-1'}
            >
              <Label
                label={t('form:labels.image')}
              />
              <input
                type={'file'}
                accept={'image/*'}
                name={field.name}
                onChange={(e) => {
                  e.target.files
                    ? field.onChange(
                        e.target.files[0]
                      )
                    : field.onChange(null)
                }}
              />
              <FormHelperText
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name={'image'}
          control={control}
        />
      </div>
      <div className={'flex'}>
        <Button
          variant={'contained'}
          type={'submit'}
        >
          {t('form:buttons.save')}
        </Button>
      </div>
    </form>
  )
}

export default Form
