import { SaveCategoryPropsInterface } from '@/features/posts/categories/redux/types'

/**
 * Build Form data for category
 * @param data
 */
export const buildFormData = (
  data: SaveCategoryPropsInterface
): FormData => {
  const formData = new FormData()

  formData.append('name', data.name)

  if (data.icon) {
    formData.append(
      'icon',
      data.icon,
      data.icon.name
    )
  }

  if (data.description) {
    formData.append(
      'description',
      data.description
    )
  }

  if (data.seo_description) {
    formData.append(
      'seo_description',
      data.seo_description
    )
  }

  if (data.seo_keywords) {
    formData.append(
      'seo_keywords',
      data.seo_keywords
    )
  }

  if (data.additional_category_ids) {
    data.additional_category_ids.forEach(
      (value, index) =>
        formData.append(
          `additional_category_ids[${index}]`,
          value.toString()
        )
    )
  }

  if (data.parent_category_id) {
    formData.append(
      'parent_category_id',
      data.parent_category_id.toString()
    )
  }

  data.units.forEach((value, index) =>
    formData.append(`units[${index}]`, value)
  )

  if (data.order) {
    formData.append(
      'order',
      data.order.toString()
    )
  }

  if (data.tags) {
    data.tags.forEach((value, index) =>
      formData.append(`tags[${index}]`, value)
    )
  }

  return formData
}
